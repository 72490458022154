<template>
  <section style="max-width: 100rem; margin: auto;">
    <!-- title -->
    <div class="text-center">
      <h1 class="mt-5">
        Pricing List
      </h1>

      <p class="mb-2 pb-75">
      </p>
      <!-- <b-button-toolbar class="text-center"  style="justify-content: center; display: flex; padding: 5px;" pill>
        <b-button-group
            size="lg"
            pill
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="background-color: silver;" 
              @click="changePrice(1)"
            >
              1 Month
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="changePrice(2)"
            >
              2 Month
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="changePrice(3)"
            >
              3 Month
            </b-button>
          </b-button-group>
        </b-button-toolbar> -->
    </div>
    <br></br>
    <!-- Payment method -->
    <!-- <section class="section-grey" id="pricing"> -->
       <section class="section-grey" id="pricing">
        <b-card body-text-variant="success" class="d-flex justify-content-center mb-1" style="max-width: 100rem; margin: auto; height: 100rem;">
        <iframe src="https://guide.fastproxy.vip/priceproxy.html" frameborder="0" style="overflow:hidden;height:100%;width:100%" height="300rem" width="100%"></iframe>
      </b-card>
<!-- <div class="container">

    <div class="row">

        <div class="col-md-12 text-center padding-bottom-40">

            <h2 class="section-title">Pricing for every business, at any stage</h2>

            <p class="section-subtitle">We always scrutinize cases to offer the lowest possible price, and you will always find our quality and service to be consistently good and cheaper than others.</p>

        </div>

        <div class="col-md-4">

            <div class="price-box">

                <ul class="pricing-list">

                    <li class="price-title">1 GB+</li>

                    <li class="price-value">$2.9</li>

                    <li class="price-title">$2.9 per GB</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>30 Days using</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>150 million+ Fast Residential IPs</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Rotating targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Country-State-City targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>API Entrance</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>HTTP(S)/SOCKS5</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Method local:port with FManager</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Whitelisted IPs Unlimited</li>
                    <li class="price-tag-line"><a href="https://socks.fastproxy.vip/billing">BUY PLAN</a></li>
                </ul>

            </div>

        </div>

        <div class="col-md-4">

            <div class="price-box">

                <ul class="pricing-list">

                    <li class="price-title">10GB+</li>

                    <li class="price-value">$25</li>

                    <li class="price-title">$2.5 per GB</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>30 Days using</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>150 million+ Fast Residential IPs</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Rotating targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Country-State-City targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>API Entrance</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>HTTP(S)/SOCKS5</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Method local:port with FManager</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Whitelisted IPs Unlimited</li>
                    <li class="price-tag-line"><a href="https://socks.fastproxy.vip/billing">BUY PLAN</a></li>

                </ul>

            </div>

        </div>

        <div class="col-md-4">

            <div class="price-box">

                <ul class="pricing-list">

                    <li class="price-title">50GB+</li>

                    <li class="price-value">$100</li>

                    <li class="price-title">$2 per GB</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>30 Days using</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>150 million+ Fast Residential IPs</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Rotating targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Country-State-City targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>API Entrance</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>HTTP(S)/SOCKS5</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Method local:port with FManager</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Whitelisted IPs Unlimited</li>
                    <li class="price-tag-line"><a href="https://socks.fastproxy.vip/billing">BUY PLAN</a></li>

                </ul>

            </div>

        </div>

        <div class="col-md-4">

            <div class="price-box">

                <ul class="pricing-list">

                    <li class="price-title">100GB+</li>

                    <li class="price-value">$170</li>

                    <li class="price-title">$1.7 per GB</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>30 Days using</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>150 million+ Fast Residential IPs</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Rotating targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Country-State-City targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>API Entrance</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>HTTP(S)/SOCKS5</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Method local:port with FManager</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Whitelisted IPs Unlimited</li>
                    <li class="price-tag-line"><a href="https://socks.fastproxy.vip/billing">BUY PLAN</a></li>

                </ul>

            </div>

        </div>

        <div class="col-md-4">

            <div class="price-box">

                <ul class="pricing-list">

                    <li class="price-title">200GB+</li>

                    <li class="price-value">$300</li>

                    <li class="price-title">$1.5 per GB</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>30 Days using</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>150 million+ Fast Residential IPs</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Rotating targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Country-State-City targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>API Entrance</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>HTTP(S)/SOCKS5</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Method local:port with FManager</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Whitelisted IPs Unlimited</li>
                    <li class="price-tag-line"><a href="https://socks.fastproxy.vip/billing">BUY PLAN</a></li>

                </ul>

            </div>

        </div>

        <div class="col-md-4">

            <div class="price-box">



                <ul class="pricing-list">

                    <li class="price-title">500GB+</li>

                    <li class="price-value">$600</li>

                    <li class="price-title">$1.2 per GB</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>30 Days using</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>150 million+ Fast Residential IPs</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Rotating targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Country-State-City targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>API Entrance</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>HTTP(S)/SOCKS5</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Method local:port with FManager</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Whitelisted IPs Unlimited</li>
                    <li class="price-tag-line"><a href="https://socks.fastproxy.vip/billing">BUY PLAN</a></li>

                </ul>

            </div>

        </div>

        <div class="col-md-12">

            <div class="price-box">
                <div class="ribbon blue"><span>Popular</span></div>
                <ul class="pricing-list">

                    <li class="price-title">1000 GB+</li>

                    <li class="price-value">$800</li>

                    <li class="price-title">$0.8 per GB</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>30 Days using</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>150 million+ Fast Residential IPs</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Rotating targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Country-State-City targeting</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>API Entrance</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>HTTP(S)/SOCKS5</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Method local:port with FManager</li>
                    <li class="price-text"><feather-icon icon="CheckIcon"/>Whitelisted IPs Unlimited</li>
                    <li class="price-tag-line"><a href="https://socks.fastproxy.vip/billing">BUY PLAN</a></li>

                </ul>

            </div>

        </div>

    </div>

</div> -->

</section>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BImg, BButton, BCardText, BListGroup,BListGroupItem, BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormInput, BBadge, BCardHeader, BCardBody, BCardFooter, BButtonToolbar, BButtonGroup} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AddFundHistory from '../table/AddFundHistory.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
const UsersPaymentRepository = RepositoryFactory.get('userPayment')

export default {
  components: {
    BCard,
    BCardText,
    BRow, 
    BCol,
    BImg,
    BButton,
    BListGroup,
    BListGroupItem,
    AddFundHistory,
    BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
    BFormInput, BBadge,
    BCardHeader,BCardBody, BCardFooter,
    BButtonToolbar, BButtonGroup
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      select: 0,
      amount: 0,
      items:[],
      userInfo: this.$store.getters['auth/userInfo'],
      sett: this.$store.getters['auth/settings'],
      code: 'ck',
      rate: '22000',
      price: []
    }
  },
  created(){
    // UsersPaymentRepository.fetch().then(rs => {
    //   this.items = rs.data.data
    //   // setTimeout(()=>{
    //   //   this.$refs.fhis.mountData()
    //   // },1000)
      
    // })
    this.rate = this.$store.getters['auth/settings'].dollar_to_vnd
    //console.log(this.$store.getters['auth/settings'])

    this.price = {
      "t1": this.sett.T0,
      "t10": this.sett.T10,
      "t50": this.sett.T50,
      "t100": this.sett.T100,
      "t200": this.sett.T200,
      "t500": this.sett.T500,
      "t1000": this.sett.T1000
    }
    this.changePrice(1)
  },
  mounted(){
    // this.$socket.on('new_notification',(data)=>{
    //   //console.log('asdasd')
    //   this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'ccc '+data.r,
    //         icon: 'AlertOctagonIcon',
    //         variant: 'danger'
    //       },
    //     });
    // })
  },
  methods:{
    showModal(selection){
      this.select = selection
      this.$refs.modal.showModal()
    },
    updateFundList(item){
      
      this.items.unshift(item)
    },
    changePrice(s){
      let ex = 1
      switch(s){
        case 1:
          ex = 0
          break;
        case 2:
          ex = 0.1
          break;
        case 3:
          ex = 0.2
          break;
      }

      this.price = {
      "t1": this.sett.T0 + ex,
      "t10": this.sett.T10 + ex,
      "t50": this.sett.T50  + ex,
      "t100": this.sett.T100 + ex,
      "t200": this.sett.T200 + ex,
      "t500": this.sett.T500 +ex,
      "t1000": this.sett.T1000 + ex
    }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
<style scoped>

ul {
	padding: 0;
}

ul, ol { 
	list-style: none; 
	list-style-image: none; 
	margin: 0; 
	padding: 0;
	color:#858585;
	font-size: 14px;
	line-height:24px;
	margin-bottom:20px;
}
	
ul li, ol li{
	font-size: 17px;
	line-height: 28px;
	list-style-type: none;
}

ul li i{
	vertical-align: middle;
	padding-right: 5px;
}


/* ========================================================== */
/* 			               07. PRICING                        */
/* ========================================================== */

.price-box{
	text-align: center;
	background-color: #fff;
	border-bottom: 3px solid #e0e0e0;
	box-shadow: rgba(33,33,33,.06) 0 4px 24px 5px;
	padding: 40px 0 30px 0;
	margin-bottom: 20px;
	border-radius: 8px 8px;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
}

.price-box:hover{
	border-bottom: 3px solid #1e90ff;
	transition: all 1s;
}

.price-box .price-subtitle,.price-box .price-text{
	color: #6a6a6a;
}

ul.pricing-list{
	padding: 0 55px;
}

ul.pricing-list li.price-title{
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	letter-spacing: 1px;
	color: #071e55;
}

ul.pricing-list li.price-value{
	font-size: 70px;
	line-height: 70px;
	display: block;
	margin-top:20px;
	margin-bottom: 10px;
	color: #071e55;
}

ul.pricing-list li.price-subtitle{
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 30px;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	color: #6a6a6a;
}

ul.pricing-list li.price-text{
	display: block;
	font-family: 'Open Sans', sans-serif;
	text-align: left;
	font-size: 14px;
	line-height: 28px;
	font-weight: 400;
	margin-bottom: 5px;
}

ul.pricing-list li.price-tag a{
	color: #fff;
	background: #1e90ff;
	border:1px solid #1e90ff;
	border-radius: 5px 5px;
	padding: 15px 30px;
	display: inline-block;
	font-size: 15px;
	line-height: 24px;
	font-weight: 600;
	margin: 30px 0 5px 0;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

ul.pricing-list li.price-tag a:hover{
	background: #037aef;
}

ul.pricing-list li.price-tag-line a{
	color: #1e90ff;
	background: transparent;
	border:1px solid #1e90ff;
	border-radius: 5px 5px;
	padding: 15px 30px;
	display: inline-block;
	font-size: 15px;
	line-height: 24px;
	font-weight: 600;
	margin: 30px 0 5px 0;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

ul.pricing-list li.price-tag-line a:hover{
	color: #fff;
	background: #037aef;
	border:1px solid #037aef;
}

.ribbon {
   position: absolute;
   right: 8px;
   top: -5px;
   z-index: 1;
   overflow: hidden;
   width: 75px; height: 75px; 
   text-align: right;
}

.ribbon span {
   font-size: 10px;
   color: #fff; 
   text-transform: uppercase; 
   text-align: center;
   font-weight: bold; line-height: 20px;
   transform: rotate(45deg);
   width: 100px;
   display: block;
   box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
   position: absolute;
   top: 19px; right: -21px;
   background-color: #1e90ff;
}

.ribbon span::before {
   content: '';
   position: absolute; 
   left: 0px; top: 100%;
   z-index: -1;
   border-left: 3px solid #79A70A;
   border-right: 3px solid transparent;
   border-bottom: 3px solid transparent;
   border-top: 3px solid #79A70A;
   border-left-color: #1e5799;
   border-top-color: #1e5799;
}

.ribbon span::after {
   content: '';
   position: absolute; 
   right: 0%; top: 100%;
   z-index: -1;
   border-right: 3px solid #79A70A;
   border-left: 3px solid transparent;
   border-bottom: 3px solid transparent;
   border-top: 3px solid #79A70A;
   border-right-color: #1e5799; border-top-color: #1e5799;
}
</style>